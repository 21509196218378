import { createContext } from 'react'

export interface ContextValue<
  W = any
> {
  ws: W
}

export const Context = createContext(null as any)

export type ContextValueType = typeof Context

export default Context
