import { createContext } from 'react'

export interface ContextValue {
  collapsed: boolean
  open: boolean
  hidden: boolean
  scrolledBottom: boolean
  setIsCollapsed: (open: boolean) => void
  setIsOpen: (open: boolean) => void
}

export const Context = createContext(null as any)

export type ContextValueType = typeof Context

export default Context
