import { createContext } from 'react'

export type ContextValue = [
  count: number,
  setUnreadCount: any
]

export const Context = createContext(null as any)

export type ContextValueType = typeof Context

export default Context
